import React from 'react';
import { Chart } from 'react-google-charts';
import styled from 'styled-components';

const PieChart = styled.div`
  padding: 0 1em 1em 1em;
  text-align: right;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0a819f;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const ChartPie = ({ pieChartData, billName }) => {
  const convertObjectToArray = (data) => {
    const result = [['name', 'time']]; // Initialize with headers

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const { name, time } = data[key];
        result.push([name, time]);
      }
    }

    return result;
  };

  const pieChartDataArray = convertObjectToArray(pieChartData);

  const convertToCSV = (data) => {
    return data.map((row) => row.join(',')).join('\n');
  };

  const downloadCSV = () => {
    const csv = convertToCSV(pieChartDataArray);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Bill ${billName} Speaker Participation.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <PieChart>
      <Chart
        width={'auto'}
        height={'auto'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={pieChartDataArray}
        options={{
          chartArea: {
            height: '95%',
            top: '0%',
            width: '95%',
            left: '25%',
          },
          sliceVisibilityThreshold: 0.05,
          is3D: true,
        }}
      />
      <DownloadButton onClick={downloadCSV}>Download Chart Data</DownloadButton>
    </PieChart>
  );
};
export default ChartPie;
