import React, { useState, useMemo, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import './SliderSankey.css';
import styled from 'styled-components';

const DownloadWrapper = styled.div`
  margin-block: 1rem;
  justify-content: flex-end;
  display: flex;
  gap: 1rem;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0a819f;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const options = {
  focusTarget: 'Tip',
  tooltip: { isHtml: true },
};

const DONATION_HEADERS = [
  'Organization',
  'Legislator',
  'Amount ($)',
  { type: 'string', role: 'tooltip', p: { html: true } },
];

const stripHtmlTags = (str) => {
	if (!str) return '';
	return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

const convertToCSV = (data) => {
  if (!data || !data.length) return '';
  return data
    .map((row) =>
      row
        .map((item) => {
          // Handle tooltip object specially
          if (typeof item === 'object') return '';
          return typeof item === 'string' ? `"${stripHtmlTags(item)}"` : item;
        })
        .join(',')
    )
    .join('\n');
};

export default function SliderSankey({
  donatingOrganizations,
  totalIndex,
  description = '',
  billName,
}) {
  const donatingOrgs = useMemo(() => {
    const orgData = donatingOrganizations?.map((item) => [
      item.name,
      item.last,
      Number(item.donations),
      item.name +
        ' total donations to ' +
        item.last +
        ': <strong>$' +
        item.donations +
        '</strong> [' +
        item.contribution_time_period +
        ']',
    ]);

    return orgData ? [DONATION_HEADERS, ...orgData] : null;
  }, [donatingOrganizations]);

  // Memoize initial data processing
  const { initialKeyTotalMap, initialSortedKeys } = useMemo(() => {
    if (!donatingOrgs?.length) {
      return { initialKeyTotalMap: {}, initialSortedKeys: [] };
    }

    // Create map of 0th index properties
    const newMap = donatingOrgs.slice(1).reduce((acc, element) => {
      const key = element[0];
      acc[key] = key in acc ? [...acc[key], element] : [element];
      return acc;
    }, {});

    // Calculate totals in single pass
    const totalsArray = Object.entries(newMap).map(([key, values]) => [
      key,
      values.reduce((acc, curr) => acc + curr[totalIndex], 0),
    ]);

    // Sort once
    const sortedKeys = totalsArray
      .sort((a, b) => b[1] - a[1])
      .map(([key]) => key);

    return {
      initialKeyTotalMap: newMap,
      initialSortedKeys: sortedKeys,
    };
  }, [donatingOrgs, totalIndex]);

  const [keyTotalMap] = useState(initialKeyTotalMap);
  const [sortedKeys] = useState(initialSortedKeys);
  const [sliderValue, setSliderValue] = useState(() =>
    Math.min(3, initialSortedKeys.length)
  );

  // Memoize filtered data calculation
  const filteredData = useMemo(() => {
    if (!keyTotalMap || !sortedKeys.length) return donatingOrgs;

    const selectedKeys = sortedKeys.slice(0, sliderValue);
    const headers = [donatingOrgs[0]];
    const filteredRows = selectedKeys.flatMap((key) => keyTotalMap[key] || []);

    return [...headers, ...filteredRows];
  }, [donatingOrgs, keyTotalMap, sortedKeys, sliderValue]);

  // Memoize slider handler
  const handleSliderChange = useCallback((event) => {
    setSliderValue(Number(event.target.value));
  }, []);

  const handleDownload = (billName) => {
    const csv = convertToCSV(filteredData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${billName} Filtered Organization Sankey.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleAllDownload = (billName) => {
    const csv = convertToCSV(donatingOrgs);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${billName} All Organization Sankey.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  if (!donatingOrgs?.length) return null;

  return (
    <>
      <div className="slider-div">
        <label htmlFor="num-organizations">
          Number of {donatingOrgs[0][0]}s:{' '}
        </label>
        <span>{sliderValue}</span>
        <div className="slider">
          <span>1</span>
          <input
            type="range"
            min="1"
            id="num-organizations"
            name="num-organizations"
            max={sortedKeys.length}
            value={sliderValue}
            onChange={handleSliderChange}
          />
          <span>{sortedKeys.length}</span>
        </div>
        <div className="description">{description}</div>
      </div>
	  <DownloadWrapper>
        <DownloadButton
          className="download-button"
          onClick={() => handleDownload(billName)}
        >
          Download Filtered Data
        </DownloadButton>
        <DownloadButton
          className="download-button"
          onClick={() => handleAllDownload(billName)}
        >
          Download All Data
        </DownloadButton>
      </DownloadWrapper>
      <Chart
        chartType="Sankey"
        width="100%"
        height="100%"
        data={filteredData}
        options={options}
      />
    </>
  );
}
