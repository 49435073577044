import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import styled from 'styled-components';
import { LinkDD } from '../Stories/Links';
import QuestionMarkIcon from '../TipSheetIcons/question-mark';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import Select from 'react-select';

const TableWrapper = styled.div`
  padding: 2rem 0;
  width: 75%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const TableHeading = styled.h3`
  margin-block-start: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
`;

const TableDescription = styled.div`
  font-size: 0.875rem;
  line-height: 1.7;
  margin-block-start: 0;
  margin-block-end: 1rem;
`;

const TopBadge = styled.span`
  background-color: #000000;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.625rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
`;

const OrganizationSelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: calc(100% + 0.5rem);
  left: -0.5rem;

  & .select-table__control {
    border: none;
    cursor: pointer;
  }

  & .select-table__indicator-separator {
    display: none;
  }

  & .select-table__indicator {
    color: #000000;
  }
`;

const OrganizationHeading = styled.h4`
  font-weight: 700;
  line-height: 1.7;
`;

const OrganizationTileContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-top: 1px solid #d9d9d9;
  padding-block: 2rem;
  margin-block-start: 2rem;
  flex-wrap: wrap;
`;

const OrganizationTile = styled.div`
  padding: 1rem;
  background: #f3f9fa;
  width: calc(25% - 2.75rem);
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const OrganizationLarge = styled.div`
  font-size: ${(props) => (props.$isLong ? '1.25rem' : '2.25rem')};
  font-weight: 700;
  line-height: 1.2;
`;

const OrganizationSmall = styled.div`
  font-size: 0.75rem;
  line-height: 1.2;
`;

const OrganizationLink = styled.span`
  padding: 1rem;
  text-align: center;

  & a {
    font-size: 0.875rem;
    line-height: 1.2;
    color: #000000;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

const ChartWrapper = styled.div`
  .google-visualization-table {
    width: 100%;

    & > div {
      scrollbar-width: thin;
    }

    &-table {
      width: 100%;
      font-size: 0.875rem;

      td {
        padding: 0.5rem;

        &:nth-child(2) {
          text-transform: uppercase;
          text-align: center;
        }

        &:nth-child(3) {
          text-align: center;
        }

        &:last-child {
          & a {
            font-weight: normal;
          }
        }

        a {
          color: #000000;
          font-size: 0.875rem;
          font-weight: 700;

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }

    &-tr {
      &-even {
        background: #f9f7f7;

        td {
          border: 0.5rem solid #f9f7f7;
        }
      }

      &-odd {
        background: #ffffff;

        td {
          border: 0.5rem solid #ffffff;
        }
      }
    }

    &-tr-head {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.2;

      .google-visualization-table-th {
        background: #f3f9fa;
        padding: 1rem;

        &.unsorted::after {
          content: url('data:image/svg+xml;utf8,<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.2" d="M0.756964 7.54496H8.24304C8.91615 7.54496 9.25271 8.39073 8.77775 8.88382L5.03472 12.7698C4.73905 13.0767 4.26095 13.0767 3.96843 12.7698L0.222245 8.88382C-0.252712 8.39073 0.0838466 7.54496 0.756964 7.54496ZM8.77775 4.11618L5.03472 0.230219C4.73905 -0.0767395 4.26095 -0.0767395 3.96843 0.230219L0.222245 4.11618C-0.252712 4.60927 0.0838466 5.45504 0.756964 5.45504H8.24304C8.91615 5.45504 9.25271 4.60927 8.77775 4.11618Z" fill="black"/></svg>');
          padding-left: 0.375rem;
          vertical-align: middle;
        }
      }
    }
  }

  .google-visualization-formatters-bars {
    float: none;

    > .google-charts-bar-w {
      background: #d9d9d9;
    }

    > span {
      height: 0.5rem;

      &:last-child {
        margin-inline-end: 1rem;
      }
    }

    > .google-charts-bar-r {
      background: #cc4839;
    }

    > .google-charts-bar-b {
      background: #028ad1;
    }
  }
`;

const Tooltip = styled.div`
  background: #d9d9d9;
  padding: 1rem;
  max-width: 300px;
  border-radius: 0.25rem;
  margin-block-end: -1px;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1.7;
`;

const TooltipButton = styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: none;
  padding: 0;
  background: none;
`;

const OptionValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionText = styled.span`
  max-width: calc(100% - 4rem);
  overflow: hidden;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0a819f;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const DownloadWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-block-start: 1rem;
  justify-content: flex-end;
`;

const chartOptions = {
  allowHtml: true,
  showRowNumber: false,
  cssClassNames: { tableCell: 'cell', width: 'width' },
};

const chartFormatters = [
  {
    type: 'BarFormat',
    column: 3,
    options: {
      width: 200,
      min: -50,
      max: 50,
    },
    maxValue: 100,
  },
  {
    type: 'ColorFormat',
    column: 2,
    ranges: [
      [0, 1, 'white', '#08842B'],
      [1, 2, 'white', '#CC4839'],
      [2, null, 'white', '#F6BE2C'],
    ],
  },
];

const chartParams = {
  view: {
    columns: [0, 1, 2, 3, 4],
  },
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

const range = (start, stop, step = 1) => {
  return Array.from(
    { length: Math.ceil((stop - start) / step) },
    (_, i) => start + i * step
  );
};

const formatAlignmentData = (alignmentData) => {
  const transformedData = alignmentData.map((item) => {
    // Create legislator name with href
    const slugify = (name) => {
      return name.toLowerCase().replace(/ /g, '-');
    };

    const legislatorLink = `<a href="https://digitaldemocracy.calmatters.org/legislators/${slugify(
      item.full_name
    )}-${item.pid}" target="_blank">${item.full_name}</a>`;

    // Convert party to shorthand
    const partyShorthand = item.party.substring(0, 3).toUpperCase();

    // Fix vote spellings
    const voteFixed =
      item.legislator_vote === 'NOE'
        ? 'NO'
        : item.legislator_vote === 'ABS'
        ? 'NVR'
        : item.legislator_vote;

    // Set number value for vote color
    const voteColor =
      item.legislator_vote === 'NOE'
        ? 1
        : item.legislator_vote === 'ABS'
        ? 2
        : 0;

    // Format alignment score
    const alignmentScore = Number(item.alignment_score);
    const alignmentScoreFormatted = `${alignmentScore.toFixed(1)}% (${
      item.alignment_opp_count
    }) [${item.alignment_time_period}]`;
    const alignmentScoreValue = alignmentScore - 50;

    const [startYear, endYear] = item.contribution_time_period
      .split(' to ')
      .map(Number);

    const donationUrl = `https://digitaldemocracy.calmatters.org/financials?receiver%5B%5D=pid-${
      item.pid
    }&transaction_type%5B%5D=candidate_donations&transaction_type%5B%5D=party_committees&giver%5B%5D=oid-${
      item.oid
    }${range(startYear, endYear + 1)
      .map((year) => `&year%5B%5D=${year}`)
      .join('')}`;

    // Format donations
    let donationsFormatted;
    if (item.donations === 'N/A') {
      donationsFormatted = 'NO RECORD';
    } else {
      const donationAmount = `<a href=${donationUrl} target="_blank">${formatCurrency(
        item.donations
      )}</a>`;
      donationsFormatted = `${donationAmount} [${item.contribution_time_period}]`;
    }

    return [
      {
        v: legislatorLink,
        csv: item.full_name,
      },
      partyShorthand,
      {
        v: voteColor,
        f: voteFixed,
        csv: voteFixed,
      },
      {
        v: alignmentScoreValue,
        f: alignmentScoreFormatted,
        csv: alignmentScoreFormatted,
      },
      {
        v: donationsFormatted,
        csv: item.donations + ' [' + item.contribution_time_period + ']',
      },
      item.name,
      item.oid,
      item.org_position,
      item.org_position_date,
      item.org_success_rate,
      item.org_success_rate_time_period,
      item.total_donated,
      item.total_donated_time_period,
    ];
  });

  const alignmentDataHeadings = [
    'Legislator',
    'Party',
    'Vote',
    {
      type: 'number',
      label: 'Alignment Score (based on # of votes) over [period]',
    },
    'Contributions [period]',
    'Organization Name',
    'Organization ID',
    'Organization Position',
    'Organization Position Date',
    'Organization Success Rate',
    'Organization Success Rate Time Period',
    'Total Donated',
    'Total Donated Time Period',
  ];

  // Add headings as the first array
  transformedData.unshift(alignmentDataHeadings);

  return transformedData;
};

const convertToCSV = (data) => {
  return data
    .map((row) =>
      row
        .map((cell) => {
          let cellValue;
          if (typeof cell === 'object' && cell.type === 'number') {
            cellValue = cell.label;
          } else if (typeof cell === 'object' && cell !== null) {
            cellValue = cell.csv;
          } else {
            cellValue = cell;
          }
          // Wrap cell value in double quotes if it contains a comma
          if (typeof cellValue === 'string' && cellValue.includes(',')) {
            cellValue = `"${cellValue}"`;
          }
          return cellValue;
        })
        .join(',')
    )
    .join('\n');
};

const downloadCSV = (data, billName) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `Bill ${billName} Leigslator Organization Alignment.csv`;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const LegislatorOrgAlignmentTable = ({ alignmentData, billName }) => {
  const transformedAlignmentData = useMemo(
    () => formatAlignmentData(alignmentData),
    [alignmentData]
  );

  // Organization unique organization values
  // Sort alphabetically for select options
  const organizationKeys = useMemo(() => {
    const uniqueNames = new Set();
    alignmentData.forEach((row) => {
      if (row.name) {
        uniqueNames.add(row.name);
      }
    });
    return [...uniqueNames].sort((a, b) => a.localeCompare(b));
  }, [alignmentData]);

  // Full organization data based on unique keys - removes any null values
  const filteredDataByOrgId = useMemo(() => {
    return organizationKeys.map((org) =>
      alignmentData.find((row) => row.name === org)
    );
  }, [alignmentData, organizationKeys]);

  // Sort filtered data by total contribution dollar amount
  const sortedDataByContribution = useMemo(() => {
    return filteredDataByOrgId.sort(
      (a, b) => (Number(b.total_donated) || 0) - (Number(a.total_donated) || 0)
    );
  }, [filteredDataByOrgId]);

  // Default selected value - highest total contribution
  const defaultSelected =
    {
      value: sortedDataByContribution[0]?.name,
      label: (
        <OptionValue>
          <OptionText>{sortedDataByContribution[0]?.name}</OptionText>
          <TopBadge>Top Donor</TopBadge>
        </OptionValue>
      ),
    } || '';

  const [selected, setSelected] = useState(defaultSelected);
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [isPopoverOpen, setPopoverOpen] = useState('');

  useEffect(() => {
    const matchingOrganizationKeys = [
      transformedAlignmentData[0],
      ...transformedAlignmentData.filter((row) => row.includes(selected.value)),
    ];
    setFilteredDatas(matchingOrganizationKeys);
  }, [selected, transformedAlignmentData]);

  return (
    <TableWrapper>
      <TableHeading>Legislator-Organization Relationships</TableHeading>
      <TableDescription>
        The drop-down menu includes organizations that took a position on this
        bill. The chart displays that organization's relationship with
        legislators in two ways:
        <ol>
          <li>
            An alignment score that shows how often the legislator votes
            consistent with the organization's agenda
          </li>
          <li>How much money the organization has given to the legislator</li>
        </ol>
      </TableDescription>
      <div>
        <OrganizationHeading>Organization</OrganizationHeading>
        <OrganizationSelectContainer>
          {organizationKeys.length > 0 ? (
            <Select
              classNamePrefix="select-table"
              defaultValue={selected}
              name="select-table"
              isSearchable={true}
              options={organizationKeys.map((opt) => ({
                value: opt,
                label:
                  opt === defaultSelected.value ? (
                    <OptionValue>
                      <OptionText>{opt}</OptionText>
                      <TopBadge>Top Donor</TopBadge>
                    </OptionValue>
                  ) : (
                    opt
                  ),
              }))}
              onChange={(e) => setSelected(e)}
            />
          ) : null}
        </OrganizationSelectContainer>
      </div>
      {/* filterDatas is an array of arrays reference alignmentDataHeadings for nested value meaning */}
      {filteredDatas.length > 0 ? (
        <OrganizationTileContainer>
          <OrganizationTile>
            <span>
              Position{' '}
              <Popover
                isOpen={isPopoverOpen === 'position'}
                positions={['top', 'bottom', 'left', 'right']}
                onClickOutside={() => setPopoverOpen('')}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#D9D9D9'}
                    arrowSize={16}
                  >
                    <Tooltip>
                      The recorded position this organization took on this bill
                    </Tooltip>
                  </ArrowContainer>
                )}
              >
                <TooltipButton
                  type="button"
                  onClick={() => setPopoverOpen('position')}
                >
                  <QuestionMarkIcon />
                </TooltipButton>
              </Popover>
            </span>
            {/* org_position */}
            <OrganizationLarge>{filteredDatas[1][7]}</OrganizationLarge>
            {/* org_position_date */}
            <OrganizationSmall>
              Recorded on {filteredDatas[1][8]}
            </OrganizationSmall>
          </OrganizationTile>
          <OrganizationTile>
            <span>
              Success Rate{' '}
              <Popover
                isOpen={isPopoverOpen === 'successRate'}
                positions={['top', 'bottom', 'left', 'right']}
                onClickOutside={() => setPopoverOpen('')}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#D9D9D9'}
                    arrowSize={16}
                  >
                    <Tooltip>
                      Overall success rate this organization has in passing its
                      agenda through the Legislature during the designated time
                      period
                    </Tooltip>
                  </ArrowContainer>
                )}
              >
                <TooltipButton
                  type="button"
                  onClick={() => setPopoverOpen('successRate')}
                >
                  <QuestionMarkIcon />
                </TooltipButton>
              </Popover>
            </span>
            <OrganizationLarge>
              {/* org_success_rate */}
              {Number(filteredDatas[1][9]).toFixed(1)}%
            </OrganizationLarge>
            {/* org_success_rate_time_period */}
            <OrganizationSmall>{filteredDatas[1][10]}</OrganizationSmall>
          </OrganizationTile>
          {/* total_donated */}
          {Number(filteredDatas[1][11]) > 0 ? (
            <OrganizationTile>
              <span>
                Total Contributions{' '}
                <Popover
                  isOpen={isPopoverOpen === 'totalContributions'}
                  positions={['top', 'bottom', 'left', 'right']}
                  onClickOutside={() => setPopoverOpen('')}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={'#D9D9D9'}
                      arrowSize={16}
                    >
                      <Tooltip>
                        Total amount of money (identified by Digital Democracy)
                        this organization has donated to legislators
                      </Tooltip>
                    </ArrowContainer>
                  )}
                >
                  <TooltipButton
                    type="button"
                    onClick={() => setPopoverOpen('totalContributions')}
                  >
                    <QuestionMarkIcon />
                  </TooltipButton>
                </Popover>
              </span>
              {/* total_donated */}
              <OrganizationLarge
                $isLong={
                  formatCurrency(Number(filteredDatas[1][11])).length > 8
                }
              >
                {formatCurrency(Number(filteredDatas[1][11]))}
              </OrganizationLarge>
              {/* total_donated_time_period */}
              <OrganizationSmall>{filteredDatas[1][12]}</OrganizationSmall>
            </OrganizationTile>
          ) : null}
          <OrganizationTile>
            <OrganizationLink>
              {/* oid */}
              {LinkDD(
                'See more about this org and its affiliates',
                filteredDatas[1][6],
                'org',
                null,
                false
              )}
            </OrganizationLink>
          </OrganizationTile>
        </OrganizationTileContainer>
      ) : null}
      <ChartWrapper>
        <Chart
          loader={<div>Loading Chart</div>}
          chartType="Table"
          width="100%"
          height="600px"
          data={filteredDatas}
          options={chartOptions}
          formatters={chartFormatters}
          chartWrapperParams={chartParams}
          controls={[]}
        />
      </ChartWrapper>
      <DownloadWrapper>
        <DownloadButton onClick={() => downloadCSV(filteredDatas, billName)}>
          Download Single Organization
        </DownloadButton>
        <DownloadButton
          onClick={() => downloadCSV(transformedAlignmentData, billName)}
        >
          Download All Organizations
        </DownloadButton>
      </DownloadWrapper>
    </TableWrapper>
  );
};
