import React, { useState } from 'react';
import Markdown from 'react-markdown';
import { LinkDD } from '../Stories/Links';
import StackedBar from './StackedBar';
import Sidebar from '../Sidebar/Sidebar';
import AiIcon from '../TipSheetIcons/ai';
import InfoGauge from './InfoGauge';
import ExternalLinkIcon from '../TipSheetIcons/external-link';
import styled from 'styled-components';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import tagsPath from '../../assets/lg_icons';

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  line-height: 1.1;
  margin: 0 0 3rem;
  max-width: 75%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const HeroContents = styled.div`
  display: flex;
  gap: 3rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ContentsContainerIdea = styled.div`
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ContentsContainerBill = styled.div`
  width: 40%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const HeroIdea = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.67;
`;

const HeroSubTitle = styled.h2`
  margin: 0;
  font-size: 1.875rem;
  line-height: 1.7;
`;

const HeroNote = styled.em`
  line-height: 1.7;
`;

const HeroSummary = styled.div`
  background: #f9f7f7;
  padding: 2rem 1rem;
  margin-top: 3rem;
`;

const SummaryTitle = styled.div`
  line-height: 1.2;
  margin: 0;
  font-weight: 600;
`;

const SummaryText = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
`;

const HeroDetails = styled.div`
  background: #f9f7f7;
  padding: 1rem;
`;

const HeroDetailsTitle = styled.p`
  font-weight: 700;
  line-height: 1.7;
  margin: 0;
  text-transform: uppercase;
`;

const ExternalLink = styled.div`
  display: inline;
  position: relative;
  cursor: pointer;
  color: #0a819f;
  font-weight: 600;

  & a {
    color: #0a819f;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  & svg {
    position: relative;
    top: 3px;
    margin-inline-start: 0.25rem;
  }
`;

const HeroAuthorList = styled.ul`
  margin: 0;
  padding: 0;
  display: inline-flex;
  list-style-type: none;
  gap: 0.25rem 1rem;
  flex-wrap: wrap;
`;

const HeroDetailsText = styled.span`
  line-height: 1.7;
`;

const HeroDetailsTags = styled.span`
  &:not(:last-child):after {
    content: ', ';
  }
`;

const VotingResultsContainer = styled.div`
  margin-block-start: 2rem;
`;

const VotingResultsLabel = styled.span`
  font-weight: 600;
`;

const VotingResultsText = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`;

const Tooltip = styled.div`
  background: #d9d9d9;
  padding: 1rem;
  max-width: 300px;
  border-radius: 0.25rem;
  margin-block-end: -1px;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1.7;
`;

const TooltipButton = styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: none;
  padding: 0;
  background: none;
`;

const HeroTagImage = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-inline-end: 0.25rem;
  vertical-align: middle;
`;

export const StoryHero = ({ storyData }) => {
  const {
    ai_summary,
    publish_date_str,
    headline,
    headline_text,
    committee,
    committee_id,
    date_text,
    bill_authors,
    bill_name,
    billname,
    content,
    tags,
    vote_outcome,
  } = storyData;

  const [isStoryPopoverOpen, setIsStoryPopoverOpen] = useState(false);
  const [isSummaryPopoverOpen, setIsSummaryPopoverOpen] = useState(false);

  const bill_analysis_summary = content?.filter(
    (item) => item.phenom === 'bill_analysis_summary'
  );
  const bill_ai_summary = content?.filter(
    (item) => item.phenom === 'bill_ai_summary'
  );

  const leadBillAuthors = bill_authors?.filter(
    (author) => author.contribution?.toLowerCase() === 'lead author'
  );

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatTagname = (tagname) => {
    if (tagname.startsWith('geo_')) {
      return capitalize(tagname.slice(4));
    }
    return capitalize(tagname);
  };

  const voterData = storyData?.assets?.find(
    (type) => type.id === 'vote_detail'
  )?.data;

  if (Array.isArray(storyData) && storyData.length === 0) {
    return null;
  }

  return (
    <div>
      <HeroTitle>{headline || headline_text}</HeroTitle>
      <HeroContents>
        <ContentsContainerIdea>
          <HeroSubTitle>Story Idea</HeroSubTitle>
          <HeroIdea>
            <Markdown>{ai_summary}</Markdown>
          </HeroIdea>
          <HeroNote>
            <Popover
              isOpen={isStoryPopoverOpen}
              positions={['top', 'bottom', 'left', 'right']}
              onClickOutside={() => setIsStoryPopoverOpen(false)}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={'#D9D9D9'}
                  arrowSize={16}
                >
                  <Tooltip>
                    The story idea above was created using "generative AI,"
                    which can make mistakes. You can fact-check this description
                    against the original text in the "Notes on Methodology"
                    section below.
                  </Tooltip>
                </ArrowContainer>
              )}
            >
              <TooltipButton
                onClick={() => setIsStoryPopoverOpen(!isStoryPopoverOpen)}
              >
                <AiIcon />
              </TooltipButton>
            </Popover>{' '}
            Tipsheet generated on {publish_date_str}
          </HeroNote>
            {bill_ai_summary?.length > 0 ? (
              <HeroSummary>
                <SummaryTitle>Bill Summary</SummaryTitle>
                <SummaryText>
                  {bill_ai_summary[0].text}{' '}
                  <Popover
                    isOpen={isSummaryPopoverOpen}
                    positions={['top', 'bottom', 'left', 'right']}
                    onClickOutside={() => setIsSummaryPopoverOpen(false)}
                    content={({ position, childRect, popoverRect }) => (
                      <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'#D9D9D9'}
                        arrowSize={16}
                      >
                        <Tooltip>
                          The bill summary above is created by "generative AI"
                          which is used to simplify language in the bill
                          analysis. You can see the original language in the
                          "Notes on Methodology" section below. The bill
                          analysis is written by staff in the Legislature and is
                          linked in the left column of this TipSheet.
                        </Tooltip>
                      </ArrowContainer>
                    )}
                  >
                    <TooltipButton
                      onClick={() =>
                        setIsSummaryPopoverOpen(!isSummaryPopoverOpen)
                      }
                    >
                      <AiIcon />
                    </TooltipButton>
                  </Popover>
                </SummaryText>
              </HeroSummary>
            ) : bill_analysis_summary?.length > 0 &&
              bill_analysis_summary[0]?.text !==
                'Failed to link this discussion to a bill analysis' ? (
              <HeroSummary>
                <SummaryTitle>Bill Summary</SummaryTitle>
                <SummaryText>{bill_analysis_summary[0].text}</SummaryText>
              </HeroSummary>
            ) : null}
        </ContentsContainerIdea>
        <ContentsContainerBill>
          <Sidebar data={storyData} />
          <HeroDetails>
            {bill_name?.length > 2 ? (
              <div>
                <HeroDetailsTitle>Bill Page:</HeroDetailsTitle>{' '}
                <ExternalLink>
                  {LinkDD(bill_name, billname, 'bil', billname, false)}
                  <ExternalLinkIcon />
                </ExternalLink>
              </div>
            ) : null}
            {leadBillAuthors?.length > 0 ? (
              <>
                <HeroDetailsTitle>Author(s):</HeroDetailsTitle>{' '}
                <HeroAuthorList>
                  {leadBillAuthors.map((author) => (
                    <li key={author.pid}>
                      <ExternalLink>
                        {LinkDD(
                          author.first.trim() + ' ' + author.last.trim(),
                          Number(author.pid),
                          'leg',
                          author.pid,
                          false
                        )}
                        ({author.party?.slice(0, 1)})
                        <ExternalLinkIcon />
                      </ExternalLink>
                    </li>
                  ))}
                  {leadBillAuthors.length < bill_authors.length ? (
                    <li>
                      <ExternalLink>
                        {LinkDD('View All', billname, 'bil', billname, false)}{' '}
                        <ExternalLinkIcon />
                      </ExternalLink>
                    </li>
                  ) : null}
                </HeroAuthorList>
              </>
            ) : null}
            {committee?.length > 5 ? (
              <div>
                <HeroDetailsTitle>Meeting:</HeroDetailsTitle>{' '}
                <ExternalLink>
                  {LinkDD(
                    committee,
                    Number(committee_id),
                    'com',
                    committee_id,
                    false
                  )}
                  <ExternalLinkIcon />
                </ExternalLink>
              </div>
            ) : null}
            {date_text ? (
              <div>
                <HeroDetailsTitle>Date:</HeroDetailsTitle>{' '}
                <HeroDetailsText>{date_text}</HeroDetailsText>
              </div>
            ) : null}
            {tags?.length > 0 ? (
              <div>
                <HeroDetailsTitle>Tags:</HeroDetailsTitle>{' '}
                {tags.map((tag) => (
                  <HeroDetailsTags key={tag.tagname}>
                    {tagsPath[tag?.tagname?.toLowerCase()] ? (
                      <HeroTagImage
                        src={tagsPath[tag?.tagname?.toLowerCase()]}
                        alt={`${tag.tagname} icon`}
                      />
                    ) : null}
                    <HeroDetailsText>
                      {formatTagname(tag.tagname)}
                    </HeroDetailsText>
                  </HeroDetailsTags>
                ))}
              </div>
            ) : null}
          </HeroDetails>
          {vote_outcome ? (
            <VotingResultsContainer>
              <VotingResultsLabel>Voting Results:</VotingResultsLabel>{' '}
              <VotingResultsText>{vote_outcome}</VotingResultsText>
            </VotingResultsContainer>
          ) : null}
          {voterData && voterData?.length > 0 && (
            <div>
              <StackedBar data={voterData} billName={bill_name}/>
            </div>
          )}
          <InfoGauge storyData={storyData} />
        </ContentsContainerBill>
      </HeroContents>
    </div>
  );
};
