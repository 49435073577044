import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as S from './Story.Style.js';

import './Story.css';
import { useLocation, useNavigate } from 'react-router-dom';
import TipSheetQuotes from '../TipSheet/TipSheet_Quotes.js';
import TipSheetNotes from '../TipSheet/TipSheetNotes.js';
import ChartTable from '../Chart/ChartTable.jsx';
import ChartPie from '../Chart/ChartPie.jsx';
import { getDuration } from '../Stories/Stories.js';
import { render_cards } from '../Stories/Stories.js';
import { LinkDD, LinkSimple } from '../Stories/Links.js';
import AlignmentMeters from './AlignmentMeters.js';
import SliderSankey from '../Chart/SliderSankey.jsx';
import { StoryHero } from './StoryHero.jsx';
import { OrganizationAlignment } from './OrganizationAlignment.jsx';
import { LegislatorOrgAlignmentTable } from './LegislatorOrgAlignmentTable.jsx';

// a number of fixes mainly in the text narrative of phenoms
// should be ultimately done during phenom generation
const temp_fixes = [
  ['Democrat members', 'Democratic members'],
  ['Democrat party', 'Democratic party'],
  ['good questions', 'questions'],
];

// function that applies above replacements
function replaceTempFixes(inString) {
  let newString = inString;
  temp_fixes?.forEach((replacement) => {
    newString = newString.replace(replacement[0], replacement[1]);
  });
  return newString;
}

// replaces legislator codes like __755 with actual first and last names from personas
// returns the modified string
export function replaceLegCodes(inString, personas) {
  const replaceList = inString.match(/__[0-9]+/g);
  let new_version = replaceTempFixes(inString);
  replaceList?.forEach((item) => {
    const leg_id = Number(item.substring(2));
    personas.forEach((persona) => {
      if (leg_id === persona['pid']) {
        new_version = new_version.replace(
          item,
          LinkDD(
            persona['first'].trim() + ' ' + persona['last'].trim(),
            leg_id,
            'leg',
            leg_id,
            true
          )
        );
      }
    });
  });
  //return replaceTempFixes(new_version);
  return <span dangerouslySetInnerHTML={{ __html: new_version }} />;
}

export default function Story() {
  //The useLocation hook returns the location object that represents the current URL.
  const [showTooltip, setShowTooltip] = useState(null);

  const location = useLocation();
  const [quoteVideo, setQuoteVideo] = useState([]);

  // UseState Hooks to store data coming over RestAPI Call
  const [aStory, setAStory] = useState([]);
  const [storyTableData, setStoryTableData] = useState([]);
  const [Personas, setPersonas] = useState([]);
  const [alignmentMeterData, setAlignmentMeterData] = useState([]);
  const [relevantOrgs, setRelevantOrgs] = useState([]);

  // AlignmentMeterComponent
  const [alignmentMeterInfo, setAlignmentMeterInfo] = useState([]);
  const [alignmentMeterOrgs, setAlignmentMeterOrgs] = useState([]);
  const [billDiscussionLinkInfo, setBillDiscussionLinkInfo] = useState([]);
  //new function by Thomas G. to be backwards compatible with old style naming.
  function check_story_id(id) {
    let tipsheet_id_fields = id.split('_');
    if (tipsheet_id_fields.length > 4 && tipsheet_id_fields[4][0] !== 'T') {
      return tipsheet_id_fields[0].concat(
        '_',
        tipsheet_id_fields[1],
        '_',
        tipsheet_id_fields[3],
        '_',
        tipsheet_id_fields[4]
      );
    } else {
      return id;
    }
  }
  const APIPathStory = check_story_id(location?.pathname?.split('/')[1]);
  // API Call End Point URL for server
  const API_URL = process.env.REACT_APP_FEATURED_API + APIPathStory;

  // handle redirects upon expired token API requests
  const navigate = useNavigate();
  const handleExpiredToken = () => {
    console.log('Expired session token, redirecting to login.');
    localStorage.removeItem('token');
    navigate('/login', { state: { from: location } });
  };

  // fetchData function is used to get JSON from Backend
  const fetchData = () => {
    let axiosT = axios.create({
      baseURL: `${API_URL}`,
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
      },
    });
    axiosT
      .get('/')
      .then((response) => {
        CheckAlignmentMeter(response.data.assets);
        FormData(response);
        setAStory(response.data);
      })
      .catch((err) => {
        console.log(err);
        handleExpiredToken();
      });
  };

  const handleVideoExtracted = (url) => {
    setQuoteVideo((prevVideos) => [...prevVideos, url]);
  };

  function stringify(arr) {
    let final = '';
    arr.forEach((item) => {
      final += item;
      final += ', ';
    });
    return final.slice(0, -2);
  }

  // FormData function is used to get data for Table index on tipsheet_html_segments from Backend
  function FormData(item) {
    const rawPersonas = item.data.personas;
    const transcript = item.data.hearing_transcript;
    const hearing_id = item.data.jsonid?.split('_')[2];

    const personaTitle = [
      'Speaker',
      'Role',
      'Affiliations',
      'Position',
      '⏱',
      '🔗',
    ];

    // save this to construct a link to DD hearing pages
    if (transcript)
      //.constructor === Object)
      setBillDiscussionLinkInfo(
        transcript
          ?.filter((utterance, index) => index === 0)
          .map((utterance) => {
            return {
              hearing_id: hearing_id,
              offset: utterance.start_time,
              file_id: utterance.vid_file_id,
            };
          })[0]
      );

    //filtering personas
    let cleanPersonas = [];
    if (rawPersonas) {
      rawPersonas.forEach((item) => {
        if (
          !item['p_type'].includes('Legislator') &&
          item['first'] !== 'Reading' &&
          item['first'] !== 'Unidentified'
        ) {
          let temp = [];
          const transcriptInfo = transcript?.filter(
            (utterance) => item['pid'] === utterance.speaker_pid
          );
          const speakerDuration = transcriptInfo
            .map((line) => line.end_time - line.start_time)
            .reduce((a, v) => (a = a + v), 0);

          temp.push({
            v: item['last'] + ', ' + item['first'],
            f: LinkDD(
              item['first'] + ' ' + item['last'],
              item['pid'],
              'per',
              item['pid'],
              true
            ),
          });

          if (item['p_type'].includes('Lobbyist')) {
            item['p_type'] = ['registered lobbyist'];
          }

          if (item['p_type'].includes('General Public')) {
            item['p_type'] = ['advocate'];
          }

          if (item['affiliation'].includes('None')) {
            item['affiliation'] = ['[none recorded]'];
            item['p_type'] = ['general public'];
          }

          if (Array.isArray(item['affiliation'])) {
            item['affiliation'] = item['affiliation'].join(', ');
          }

          temp.push(stringify(item['p_type']));
          temp.push(item['affiliation']);

          item['position'] =
            transcriptInfo?.length > 0
              ? transcriptInfo[0].alignment
              : 'undetermined';
          let voteVal = 2;
          if (item['position'].includes('_')) {
            voteVal = 2;
          } else if (item['position'].slice(0, 3).toLowerCase() === 'for') {
            voteVal = 0;
          } else if (item['position'].slice(0, 7).toLowerCase() === 'against') {
            voteVal = 1;
          }
          temp.push({ v: voteVal, f: item['position'] });

          item['time'] =
            transcriptInfo?.length > 0
              ? { f: getDuration(speakerDuration), v: speakerDuration }
              : 'N/A';
          item['link'] =
            transcriptInfo?.length > 0
              ? {
                  f:
                    ' <span class="no-decoration">' +
                    LinkDD(
                      '⏩',
                      hearing_id,
                      'hea',
                      null,
                      true,
                      null,
                      '?t=' +
                        transcriptInfo[0].start_time +
                        '&f=' +
                        transcriptInfo[0].vid_file_id
                    ) +
                    '</span>',
                  v: hearing_id,
                }
              : 'N/A';
          item['participation'] =
            transcriptInfo?.length > 0
              ? {
                  f:
                    getDuration(speakerDuration) +
                    ' <span class="no-decoration">' +
                    LinkDD(
                      '⏩',
                      hearing_id,
                      'hea',
                      null,
                      true,
                      null,
                      '?t=' +
                        transcriptInfo[0].start_time +
                        '&f=' +
                        transcriptInfo[0].vid_file_id
                    ) +
                    '</span>',
                  v: speakerDuration,
                }
              : 'N/A';
          temp.push(item['time']);
          temp.push(item['link']);

          cleanPersonas.push(temp);
        }
      });
    }

    if (cleanPersonas.length > 0) {
      cleanPersonas = cleanPersonas.toSorted((a, b) => b[4]['v'] - a[4]['v']);
      cleanPersonas.unshift(personaTitle);
    }
    setPersonas(cleanPersonas);
  }

  function CheckAlignmentMeter(assetData) {
    if (assetData) {
      let newAlignmentData = [];
      let newOrgs = [];

      assetData.map((item) => {
        if (item.asset_type === 'alignment_meter') {
          newAlignmentData.push(item);
        }
        if (item.caption === 'Organization Alignments') {
          if (relevantOrgs.length < 1) {
            Object.keys(item.data).map((k) => {
              newOrgs.push(item.data[k]['Org ID']);
            });
          }
        }
      });
      setAlignmentMeterData(newAlignmentData);
      setRelevantOrgs(newOrgs);
      return true;
    }
    return false;
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (alignmentMeterData.length === 0 || relevantOrgs.length === 0) return;
    let pids = Object.keys(alignmentMeterData[0]['data']);
    let allOrgNames = new Set();
    let allLegDatas = [];

    // construct data for AlignmentMeter Google Chart components
    // requires header as first value (["Label", "Value"])
    pids.forEach((pid) => {
      let leg_data = [['Label', 'Value']];
      let leg_name = '';

      for (var id in relevantOrgs) {
        const org_id = relevantOrgs[id];

        if (org_id < 0 && alignmentMeterData[0]['data'][pid][org_id]) {
          var org = alignmentMeterData[0]['data'][pid][org_id][0];
          const value_raw = Number(parseFloat(org['alignment']).toFixed(1));
          leg_data.push([
            org['name'],
            {
              v: value_raw,
              f: value_raw.toString() + '%',
              totalVotes: org['total_alignment_opportunities'],
            },
          ]);
          if (leg_name.length < 1) {
            leg_name = org['first'] + ' ' + org['last'];
          }
          allOrgNames.add(org['name']);
        }
      }

      allLegDatas.push({ leg_name: leg_name, org_alignment: leg_data });
    });

    setAlignmentMeterInfo(allLegDatas);
    let orgNameList = [...allOrgNames];
    setAlignmentMeterOrgs(orgNameList);
  }, [alignmentMeterData, relevantOrgs]);

  const pieChartData = aStory?.assets?.find(
    (type) => type.id === 'speaker_participation_chart'
  )?.data;

  useEffect(() => {
    if (aStory?.assets) {
      const matchingAssets = aStory.assets.filter((asset) =>
        [
          'geo_concepts_table',
          'latest_bill_support_opposition',
          'bill_vote_history',
        ].includes(asset.id)
      );
      setStoryTableData(matchingAssets);
    }
  }, [aStory]);

  const storyTableTitle = (id) => {
    switch (id) {
      case 'geo_concepts_table':
        return 'Location-specific Terms';
      case 'latest_bill_support_opposition':
        return 'Organization Positions on this Bill';
      case 'bill_vote_history':
        return 'Previous Votes on this Bill';
      default:
        return '';
    }
  };

  const alignmentData = aStory?.assets?.find(
    (type) => type.id === 'vote_alignment_org_contribution_success'
  )?.data;

  const donatingOrganizations = alignmentData?.filter(
    (item) => item.donations !== 'N/A'
  );

  return (
    <S.StoryWrapper>
      <S.StoryContainer>
        <StoryHero storyData={aStory} />
        <S.DetailView>
          <OrganizationAlignment story={aStory} />
          {alignmentData && alignmentData?.length > 1 && (
            <LegislatorOrgAlignmentTable alignmentData={alignmentData} billName={aStory?.billname} />
          )}

          {aStory.content?.filter((p) => p.phenom === 'governor_veto_message')
            .length > 0 && (
            <S.TipSheetBox>
              <S.BoxTitle>Governor's Veto Message</S.BoxTitle>
              {
                aStory.content
                  .filter((p) => p.phenom === 'governor_veto_message')
                  .map((phenom) => {
                    if (
                      phenom.tips &&
                      phenom.tips.length > 0 &&
                      phenom.tips[0] &&
                      phenom.tips[0].link_url.length > 0
                    ) {
                      return (
                        <S.BoxNote>
                          {LinkSimple(
                            phenom.tips[0].data,
                            phenom.tips[0].link_url,
                            null,
                            false
                          )}
                        </S.BoxNote>
                      );
                    } else {
                      return (
                        <>
                          <S.BoxNote>
                            This is the veto message pulled directly from state
                            records.
                          </S.BoxNote>
                          <div className="pullquote veto-message">
                            {phenom.tips[0].data
                              .replace('\n\n', '@')
                              .split('@')
                              .map((p, i) => {
                                return <p key={i}>{p}</p>;
                              })}
                          </div>
                        </>
                      );
                    }
                  })[0]
              }
            </S.TipSheetBox>
          )}
          {Number(aStory?.video_duration) < 180 && (
            <S.TipSheetBox>
              <S.BoxTitle>No Quote Suggestions</S.BoxTitle>
              <S.BoxNote>
                Due to the short length of this discussion, there are no good
                automatic quote suggestions by Tip Sheets. Please visit the{' '}
                {LinkDD(
                  'video and transcript',
                  billDiscussionLinkInfo.hearing_id,
                  'hea',
                  null,
                  false,
                  null,
                  '?t=' +
                    billDiscussionLinkInfo.offset +
                    '&f=' +
                    billDiscussionLinkInfo.file_id
                )}{' '}
                for the full discussion.
              </S.BoxNote>
            </S.TipSheetBox>
          )}
          {Number(aStory?.video_duration) > 180 &&
          aStory.pullquotes?.length > 0 ? (
            <S.TipSheetBox>
              <S.BoxTitle>Quotes</S.BoxTitle>
              {aStory.bill_name?.toLowerCase() === 'no bill discussed' && (
                <S.BoxNote>
                  The quotes in this carousel are intended to reflect positions
                  of legislators and those who gave testimony. The quotes are
                  linked to the full transcript for context and alternate
                  quotes.
                </S.BoxNote>
              )}
              {aStory.bill_name?.toLowerCase() !== 'no bill discussed' && (
                <S.BoxNote>
                  The quotes in this carousel are intended to reflect positions
                  for and against this bill from legislators and those who gave
                  testimony. The quotes are linked to the full transcript for
                  context and alternate quotes.
                </S.BoxNote>
              )}
              <TipSheetQuotes
                api_url={API_URL}
                pullquotes={aStory.pullquotes}
                onVideoExtracted={handleVideoExtracted}
                jsonid={aStory.jsonid}
                vote_detail={aStory.assets?.filter(
                  (asset) => asset.id === 'vote_detail'
                )}
                hearing_transcript={aStory.hearing_transcript}
                bill_authors={aStory.bill_authors}
                informational_hearing={
                  aStory.bill_name?.toLowerCase() === 'no bill discussed'
                }
              />
            </S.TipSheetBox>
          ) : null}

          {Personas && Personas.length > 0 ? (
            <S.TipSheetBox>
              <S.BoxTitle>Witnesses</S.BoxTitle>
              <ChartTable
                dataTable={Personas}
                formatters={[
                  {
                    type: 'ColorFormat',
                    column: 3,
                    ranges: [
                      [0, 1, 'white', '#386f1f'],
                      [1, 2, 'white', '#b0120a'],
                      [2, null, 'white', 'orange'],
                    ],
                  },
                ]}
				id="witnesses"
				billName={aStory?.billname}
              />
            </S.TipSheetBox>
          ) : null}

          {storyTableData.length > 0
            ? storyTableData.map((table) => (
                <S.TipSheetBox key={table.id}>
                  <S.BoxTitle>{storyTableTitle(table.id)}</S.BoxTitle>
                  <S.BoxNote>
                    {table.id === 'geo_concepts_table'
                      ? 'This Tip Sheet is associated with region(s) on the strength of terms listed below.'
                      : table.caption}
                  </S.BoxNote>
                  <ChartTable
                    dataTable={table.data}
                    options={{ pageSize: 10 }}
                    id={table.id}
					billName={aStory?.billname}
                  />
                </S.TipSheetBox>
              ))
            : null}

          {pieChartData ? (
            <S.TipSheetBox>
              <S.BoxTitle>Speaker Participation</S.BoxTitle>
              <ChartPie pieChartData={pieChartData} billName={aStory?.billname} />
            </S.TipSheetBox>
          ) : null}

          {billDiscussionLinkInfo?.file_id && (
            <S.TipSheetBox>
              <S.BoxTitle>Notes on Methodology</S.BoxTitle>
              <TipSheetNotes tipsheet={aStory} bill={billDiscussionLinkInfo} />
            </S.TipSheetBox>
          )}
          <S.Bar3>
            {donatingOrganizations?.length > 1 && (
              <S.ImageWrap key={1003}>
                <div>
                  <S.BoxTitle> Financial Relationships </S.BoxTitle>
                  <S.BoxNote>
                    This display is intended to quickly show how much money has
                    been given to each member of this committee (right) by
                    organizations (left) that have taken a position on this
                    bill. The size of the bars is proportionate to the total
                    amount of direct contributions to the legislator and the
                    exact amounts are revealed by hovering on each link. The
                    data is based on available records between 2015 and 2024.
                  </S.BoxNote>

                  <div
                    className={
                      donatingOrganizations?.length < 11
                        ? 'sankey-container-small'
                        : donatingOrganizations.length < 25
                        ? 'sankey-container-medium'
                        : donatingOrganizations.length < 40
                        ? 'sankey-container-large'
                        : 'sankey-container-xlarge'
                    }
                  >
                    <SliderSankey
                      donatingOrganizations={donatingOrganizations}
                      totalIndex={2}
                      description="Shows the top N organizations by total amount given."
					  billName={aStory?.billname}
                    />
                  </div>
                </div>
              </S.ImageWrap>
            )}

            {alignmentMeterInfo?.length > 0 &&
              alignmentMeterOrgs?.length > 0 && (
                <S.ImageWrap key={1004}>
                  <AlignmentMeters
                    legOrgAlignmentData={alignmentMeterInfo}
                    orgs={alignmentMeterOrgs}
                  />
                </S.ImageWrap>
              )}

            {aStory.related_tipsheets?.length > 1 && (
              <S.ImageWrap>
                <S.BoxTitle> Related TipSheets </S.BoxTitle>
                <S.BoxNote>
                  These tipsheets were created for earlier votes on this same
                  bill.
                </S.BoxNote>
                {render_cards(
                  aStory.related_tipsheets
                    .filter((story) => story.id !== aStory.jsonid)
                    .toSorted(
                      (a, b) =>
                        b.tipsheet_tile_json.score - a.tipsheet_tile_json.score
                    ),
                  showTooltip,
                  setShowTooltip
                )}
              </S.ImageWrap>
            )}
          </S.Bar3>
        </S.DetailView>
      </S.StoryContainer>
    </S.StoryWrapper>
  );
}
