import React from 'react';
import styled from "styled-components";

const HorizonTagsBarWrap = styled.div`
    width: 100%;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;

	@media (max-width: 600px) {
		justify-content: center;
	}
`;

const Img = styled.img`
    height: 16px;
`;

const Tag = styled.div`
    display: inline-grid;
    grid-column-gap: 6px;
    grid-auto-flow: column;
    align-items: center;
    font-size: 12px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 6px;
`;

export default function HorizonTagsBar({
  dataFromParent,
  dataFromParent2,
  handleCheckboxClick,
}) {
  if (!dataFromParent.length) {
    return null;
  }

  return (
    <HorizonTagsBarWrap>
      {dataFromParent.map(({ svg, icon }, index) => {
        return index < 9 ? (
          <Tag
            selected22={dataFromParent2.includes(icon)}
            name={icon}
            key={index}
          >
            <span
              className="tag-remove"
              onClick={function () {
                handleCheckboxClick(icon);
              }}
            ></span>
            <Img name={icon} src={svg.default} />
            <span className="tag-content" name={icon}>
              {icon === 'public_safety'
                ? 'Public Safety'
                : icon.substring(0, 3) === 'geo'
                ? icon.substring(3).replace(/_/g, ' ').toUpperCase()
                : icon[0].toUpperCase() + icon.substring(1)}
            </span>
          </Tag>
        ) : null;
      })}
    </HorizonTagsBarWrap>
  );
}
