import React, { Fragment, useState } from 'react';
import { Chart } from 'react-google-charts';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import QuestionMarkIcon from '../TipSheetIcons/question-mark';

export const options = {
  isStacked: true,
  chartArea: { width: '100%' },
  colors: ['#028AD1', '#CC4839', '#D9D9D9'],
  hAxis: {
    minValue: 0,
    ticks: [],
  },
  vAxis: {
    textPosition: 'none',
  },
  annotations: {
    textStyle: {
      italic: true,
      color: 'black',
    },
    alwaysOutside: true,
    stem: {
      color: 'transparent',
      length: 0,
    },
  },
  bar: { groupWidth: '90%' },
  legend: { position: 'bottom' },
};

const BarChartWrapper = styled.div`
  position: relative;
`;

const BarChart = styled.div`
  margin: 0 2rem 0 4rem;
`;

const BarChartContainer = styled.div`
  font-weight: 700;
  line-height: 1.7;

  & :nth-child(1) {
    top: 25%;
  }

  & :nth-child(2) {
    top: 45%;
  }

  & :nth-child(3) {
    top: 66.67%;
  }
`;

const BarChartLabel = styled.span`
  position: absolute;
  left: 0;
`;

const BarChartVotes = styled.span`
  position: absolute;
  right: 0;
`;

const DownloadWrapper = styled.div`
  margin-block-start: 1rem;
  text-align: right;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0a819f;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const Tooltip = styled.div`
  background: #d9d9d9;
  padding: 1rem;
  max-width: 300px;
  border-radius: 0.25rem;
  margin-block-end: -1px;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1.7;
`;

const TooltipButton = styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  top: calc(66.67% + 0.375rem);
  left: 2.25rem;
`;

// These labels match hardcoded values in getVotePartyData() in Story.jsx
const labels = ['AYE', 'NO', 'NVR'];

const convertVoteData = (data) => {
  // Initialize counters
  const voteCountsByType = {
    AYE: { Democratic: 0, Republican: 0, Other: 0 },
    NO: { Democratic: 0, Republican: 0, Other: 0 },
    NVR: { Democratic: 0, Republican: 0, Other: 0 },
  };

  // Count votes by type and party
  data.forEach(({ party, vote }) => {
    // Normalize vote types
    let normalizedVote = vote;
    if (vote === 'NOE') normalizedVote = 'NO';
    if (vote === 'ABS') normalizedVote = 'NVR';

    // Normalize party names
    const normalizedParty = party === 'Democrat' ? 'Democratic' : party;

    // Count the vote
    if (normalizedVote in voteCountsByType) {
      if (
        normalizedParty === 'Democratic' ||
        normalizedParty === 'Republican'
      ) {
        voteCountsByType[normalizedVote][normalizedParty]++;
      } else {
        voteCountsByType[normalizedVote].Other++;
      }
    }
  });

  // Format data for chart
  const voterData = [
    ['Vote', 'Democratic', 'Republican', 'Other'],
    [
      'AYE',
      voteCountsByType.AYE.Democratic,
      voteCountsByType.AYE.Republican,
      voteCountsByType.AYE.Other,
    ],
    [
      'NO',
      voteCountsByType.NO.Democratic,
      voteCountsByType.NO.Republican,
      voteCountsByType.NO.Other,
    ],
    [
      'NVR',
      voteCountsByType.NVR.Democratic,
      voteCountsByType.NVR.Republican,
      voteCountsByType.NVR.Other,
    ],
  ];

  // Calculate totals
  const totalVotes = voterData
    .slice(1)
    .map((row) => String(row[1] + row[2] + row[3]));

  return {
    voterData,
    totalVotes,
  };
};

const convertToCSV = (data) => {
  if (!data || !data.length) return '';
  return data
    .map((row) =>
      row
        .map((item) => (typeof item === 'string' ? `"${item}"` : item))
        .join(',')
    )
    .join('\n');
};

export default function StackedBar({ data, billName }) {
  const processedData = convertVoteData(data);
  const { voterData, totalVotes } = processedData;
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  if (!voterData.length > 0 || !totalVotes.length > 0) {
    return null;
  }

  const handleDownload = () => {
    const csv = convertToCSV(voterData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${billName} Voting Results.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <>
      <BarChartWrapper>
        <BarChart>
          <Chart
            chartType="BarChart"
            height="200px"
            data={voterData}
            options={options}
          />
        </BarChart>
        <BarChartContainer>
          {labels.map((label) => (
            <Fragment key={label}>
              <BarChartLabel key={label}>{label}</BarChartLabel>
              {label === 'NVR' ? (
                <Popover
                  isOpen={isPopoverOpen}
                  positions={['top', 'bottom', 'left', 'right']}
                  onClickOutside={() => setPopoverOpen(false)}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor={'#D9D9D9'}
                      arrowSize={16}
                    >
                      <Tooltip>“No Vote Recorded”</Tooltip>
                    </ArrowContainer>
                  )}
                >
                  <TooltipButton
                    type="button"
                    onClick={() => setPopoverOpen(!isPopoverOpen)}
                  >
                    <QuestionMarkIcon />
                  </TooltipButton>
                </Popover>
              ) : null}
            </Fragment>
          ))}
        </BarChartContainer>
        <BarChartContainer>
          {totalVotes.length > 0
            ? totalVotes.map((vote) => (
                <BarChartVotes key={vote}>({vote})</BarChartVotes>
              ))
            : null}
        </BarChartContainer>
      </BarChartWrapper>
      <DownloadWrapper>
        <DownloadButton onClick={handleDownload}>Download Voting Results</DownloadButton>
      </DownloadWrapper>
    </>
  );
}
