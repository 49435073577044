import React, { useCallback, useMemo } from 'react';
import { Chart } from 'react-google-charts';
import './ChartTable.css';
import styled from 'styled-components';

const TableChart = styled.div`
  padding: 0 1em 1em 1em;
`;

const DownloadWrapper = styled.div`
  margin-block-start: 1rem;
  text-align: right;
`;

const DownloadButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #0a819f;
  color: #ffffff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`;

const stripHtmlTags = (str) => {
  if (!str) return '';
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

const convertToCSV = (data, id) => {
  if (!data?.length) return '';

  let processedData = [...data];

  if (id === 'latest_bill_support_opposition') {
    // Process only the data rows, keep headers intact
    processedData = [
      data[0],
      ...data.slice(1).map((row) =>
        row.map((cell, index) =>
          // Assuming organization name is in first column
          index === 0 ? stripHtmlTags(cell) : cell
        )
      ),
    ];
  }

  if (id === 'witnesses') {
    processedData = [
      data[0].map((cell) =>
        typeof cell === 'object' && cell !== null ? cell.v : cell
      ),
      ...data
        .slice(1, -1)
        .map((row) =>
          row.map((cell, index) =>
            typeof cell === 'object' && cell !== null
              ? index === 0 || index === row.length - 1
                ? cell.v
                : cell.f
              : cell
          )
        ),
    ];
  }

  const headers = processedData[0].map((col) => `"${col}"`).join(',');
  const rows = processedData
    .slice(1)
    .map((row) => row.map((cell) => `"${cell}"`).join(','));

  return `${headers}\n${rows.join('\n')}`;
};

const convertVoteHistoryData = (data) => {
  const headers = [
    'Location',
    'Vote Date',
    'Motion',
    'Ayes',
    'Noes',
    'NVRs',
    'Outcome',
  ];

  return [
    headers,
    ...data.map((item) => [
      item.name,
      item.vote_date,
      item.motion_text,
      Number(item.ayes),
      Number(item.noes),
      Number(item.nvrs),
      item.outcome,
    ]),
  ];
};

const convertDataOrgPosition = (data) => {
  const headers = [
    'Organization',
    'Position',
    'Date',
    'Source',
    'Contrib. to Legislators',
    'Contrib. to Governor',
  ];

  return [
    headers,
    ...data.map((item) => [
      `<a href="https://digitaldemocracy.calmatters.org/organizations/${item.oc_id}" target="_blank" key="${item.oc_id}">${item.name}</a>`,
      item.alignment,
      item.alignment_date,
      'Bill Analysis',
      item.total_donated_current_legislators === 'NO RECORD'
        ? 'NO RECORD'
        : `$${parseFloat(
            item.total_donated_current_legislators
          ).toLocaleString()} [${item.leg_time_period}]`,
      item.total_donated_to_gov === 'NO RECORD'
        ? 'NO RECORD'
        : `$${parseFloat(item.total_donated_to_gov).toLocaleString()} [${
            item.time_period_gov
          }]`,
    ]),
  ];
};

const convertGeographicData = (data) => {
  const headers = ['Region', 'Term', 'Found in', 'Points'];

  return [
    headers,
    ...data.map((item) => [
      item.Region,
      item.Term,
      item['Found in'],
      parseInt(String(item.Points), 10),
    ]),
  ];
};

const DEFAULT_OPTIONS = {
  allowHtml: true,
  cssClassNames: { tableCell: 'cell', width: 'width' },
  showRowNumber: false,
  explorer: { axis: 'vertical' },
  page: 'enable',
  pageSize: 10,
};

const ChartTable = ({ dataTable, options, formatters, id, billName }) => {
  const convertedData = useMemo(() => {
    if (!dataTable?.length) return null;

    switch (id) {
      case 'latest_bill_support_opposition':
        return convertDataOrgPosition(dataTable);
      case 'bill_vote_history':
        return convertVoteHistoryData(dataTable);
      case 'geo_concepts_table':
        return convertGeographicData(dataTable);
      default:
        return dataTable;
    }
  }, [dataTable, id]);

  const handleDownload = useCallback(() => {
    if (!convertedData) return;

    const csv = convertToCSV(convertedData, id);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${billName} ${id
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);
  }, [billName, convertedData, id]);

  // overwrites with any option passed in
  const finalOptions = useMemo(
    () => ({
      ...DEFAULT_OPTIONS,
      ...options,
    }),
    [options]
  );

  if (!convertedData) {
    return null;
  }

  return (
    <TableChart>
      <Chart
        width="100%"
        chartType="Table"
        loader={<div>Loading Chart</div>}
        data={convertedData}
        options={finalOptions}
        formatters={formatters}
      />
      <DownloadWrapper>
        <DownloadButton onClick={handleDownload}>
          Download Table Data
        </DownloadButton>
      </DownloadWrapper>
    </TableChart>
  );
};
export default ChartTable;
