import React, { useState } from 'react';
import './Stories.css';
import icons from '../../assets/lg_icons.js';
import { Link } from 'react-router-dom';

export const null_phenoms = [
  'endofintro',
  'subjects',
  'presenter',
  'motion',
  'vote_results',
  'intro',
  'bill_name',
  'chair',
  'bill_digest',
  'bill_analysis_summary',
  'intro_length',
  'bill_mentions',
  'number_speakers_opponents',
  'vote_result',
  'find_engagement',
  'a_to_b',
  'a_to_b_questions',
  'leginfo_bill_link',
];

export const story_phenoms = ['legislator_votes_against_donor'];
export const geoTagGraphics = (tipsheetTags) => {
  tipsheetTags.filter((tag) => tag.substring(0, 4) === 'geo_').map();
};
export const tagGraphics = (tipsheetTags) => {
  const tags = [];
  tipsheetTags.forEach((tag) => {
    if (icons[tag.tagname.toLowerCase()]) {
      tags.push(tag);
    }
  });
  return (
    tags.length > 0 && (
      <div className="sm-icons">
        {tags.map((tag) => {
          return (
            <img
              src={icons[tag.tagname.toLowerCase()]}
              style={{
                height: '30px',
                filter:
                  'brightness(0) saturate(100%) invert(84%) sepia(53%) saturate(2464%) hue-rotate(354deg) brightness(110%) contrast(98%)',
              }}
              alt={'pic of ' + tag.tagname.toLowerCase()}
              title={tag.tagname.toLowerCase()}
            />
          );
        })}
      </div>
    )
  );
};

export const voteOutcome = (tipsheet, showTooltip, setShowTooltip) => {
  var tile_json = tipsheet.tipsheet_tile_json;
  var today = new Date();
  var days_ago = new Date(new Date().setDate(today.getDate() - 3));

  if (tile_json.hasOwnProperty('vote_outcome')) {
    let voteClass = '';
    let toolTipText = '';
    let kkey = Date.now();
    if (tile_json.vote_outcome?.slice(0, 3) === 'pas') {
      voteClass = 'vote-pass-card';
      toolTipText = 'motion passed';
    } else if (tile_json.vote_outcome?.slice(0, 3) === 'fai') {
      voteClass = 'vote-fail-card';
      toolTipText = 'motion failed';
    } else if (
      tile_json.tipsheet_type === 'bill' &&
      tipsheet.tile_bill_num?.toLowerCase() !== 'informational' &&
      new Date(tipsheet.tile_date) >= days_ago
    ) {
      voteClass = 'vote-not-available';
      toolTipText =
        'Official vote results have not yet been obtained. Tip Sheet will be updated as soon as possible.';
    } else {
      return;
    }
    return (
      <span
        key={kkey}
        className="abbreviation"
        onMouseEnter={() => setShowTooltip('vote-status')}
        onMouseLeave={() => setShowTooltip(null)}
      >
        <span className={voteClass}>
          {showTooltip === 'vote-status' && <Tooltip text={toolTipText} />}
        </span>
      </span>
    );
  }
};

export const getTriggerScore = (tipsheet) => {
  if (
    tipsheet.tipsheet_tile_json.score ||
    tipsheet.tipsheet_tile_json.score === 0
  ) {
    return tipsheet.tipsheet_tile_json.score;
  }
  const content_obj = tipsheet.tipsheet_tile_json.content;
  var trigger_score = 0;
  for (var key in content_obj) {
    var phenom = content_obj[key]['phenom'];
    if (!null_phenoms.includes(phenom)) {
      trigger_score += 1;
    }
  }
  return trigger_score;
};

export const getTrigger_texts = (tipsheet) => {
  if (tipsheet.trigger_texts) {
    return tipsheet.trigger_texts.join(', ');
  } else {
    return null;
  }
};

// returns the first "lead author" if "bill_authors" property exists, otherwise,
// returns the first entry in personas, which is traditionally the presenter.
export const getAuthor = (tile) => {
  if (tile.hasOwnProperty('bill_authors')) {
    for (let idx in tile.bill_authors) {
      if (tile.bill_authors[idx].contribution.toLowerCase() === 'lead author') {
        return (
          '(' +
          tile.bill_authors[idx].first +
          ' ' +
          tile.bill_authors[idx].last +
          ')'
        );
      }
    }
  }
  if (tile.hasOwnProperty('personas') && tile.personas.length > 0) {
    return '(' + tile.personas[0].first + ' ' + tile.personas[0].last + ')';
  }
};

export const getDuration = (duration_str) => {
  const duration = Number(duration_str);
  let response = '';
  if (duration === 0) return response;
  let seconds = Number(duration_str);
  let minutes = Math.floor(seconds / 60);
  let hours = 0;
  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  if (hours > 0) response += hours.toString() + 'h ';
  if (minutes === 0) response += seconds.toString() + ' sec';
  else response += minutes.toString() + ' min';
  return response;
};

export const Tooltip = ({ text }) => {
  return <div className="tooltip">{text}</div>;
};

export const getAnnotatedScore = (tipsheet, showTooltip, setShowTooltip) => {
  const content_obj = tipsheet.content;

  const score_text = Object.keys(content_obj)
    .map((key, index) => {
      if (content_obj[key]['score'] && content_obj[key]['score'] > 0) {
        let tipText = ' +' + content_obj[key]['score'].toString() + ': ';
        if (content_obj[key].hasOwnProperty('description')) {
          tipText += content_obj[key]['description'];
        }
        return tipText;
      }
      return null;
    })
    .filter(Boolean)
    .map((t) => <li>{t}</li>);

  return (
    <div
      className="score"
      key={tipsheet.jsonid}
      onMouseEnter={() => setShowTooltip(tipsheet.jsonid)}
      onMouseLeave={() => setShowTooltip(null)}
    >
      {tipsheet.score}
      {showTooltip === tipsheet.jsonid && tipsheet.score > 0 && (
        <Tooltip text={<ul>{score_text}</ul>} />
      )}
    </div>
  );
};

// main code to render a group of tipsheet tiles (cards)
// additional_selection_function(card) runs per card and produces additional content in the selection section
export const render_cards = (
  cards,
  showTooltip,
  setShowTooltip,
  additional_selection_function = null
) => {
  return (
    <div className="cards">
      {cards.map((card, index) => {
        return (
          <Link to={"/"+card.id} target="_blank" className="link" key={index}>
            <div className="Storiescontainer">
              <div className="headline">
                <div className="featured-card-data date">
                  {card.tile_date_line}
                </div>
                <h3>{card.headline}</h3>
                <div className="location">
                  {' '}
                  {card.tile_committee !== 'None' && card.tile_committee + ', '}
                  {getDuration(card.tipsheet_tile_json.video_duration).length >
                    0 &&
                    getDuration(card.tipsheet_tile_json.video_duration) + ', '}
                  {card.tile_bill_num} {getAuthor(card.tipsheet_tile_json)}{' '}
                  {voteOutcome(card, showTooltip, setShowTooltip)}
                </div>
              </div>
              <span className="selection">
                {getAnnotatedScore(
                  card.tipsheet_tile_json,
                  showTooltip,
                  setShowTooltip
                )}
                {additional_selection_function &&
                  additional_selection_function(card)}
              </span>
              {tagGraphics(card.tipsheet_tile_json.tags)}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default function Stories({ stories, lawmakers }) {
  const [showTooltip, setShowTooltip] = useState(null);
  let storiesByWeek = {};

  stories.forEach((story) => {
    if (!storiesByWeek.hasOwnProperty(story['week'])) {
      storiesByWeek[story['week']] = [];
    }
    storiesByWeek[story['week']].push(story);
  });

  const speaking_sign = (card) => {
    let personas = card?.tipsheet_tile_json?.personas;
    if (!personas) return;
    let lawmaker_speakers = lawmakers.map((lawmaker) => lawmaker.pid);
    if (
      personas
        .filter((persona) => persona.p_type.includes('speaker'))
        ?.map((persona) => persona.pid)
        .filter((pid) => lawmaker_speakers.includes(pid)).length > 0
    ) {
      return (
        <span
          key={card.tipsheet_tile_json.jsonid + '_speaker'}
          className="speaking-sign"
          onMouseEnter={() => setShowTooltip('speaking-sign')}
          onMouseLeave={() => setShowTooltip(null)}
        >
          🗣
          {showTooltip === 'speaking-sign' && (
            <Tooltip
              text={
                'At least one of the selected lawmakers from the filters above ' +
                'is a speaker at this event and has transcribed lines captured in the transcript.'
              }
            />
          )}
        </span>
      );
    }
  };
  return (
    <>
      {Object.keys(storiesByWeek)
        .toSorted((a, b) => b - a, 0)
        .map((key) => storiesByWeek[key])
        .map((card_group, group_index) => (
          <div className="story-periodic-group" key={group_index}>
            <div className="period-header" key={group_index}>
              Week of {card_group[0]['week_str']} ({card_group.length} Tip Sheet
              {card_group.length > 1 ? 's' : ''})
            </div>
            {render_cards(
              card_group,
              showTooltip,
              setShowTooltip,
              lawmakers ? speaking_sign : null
            )}
          </div>
        ))}
    </>
  );
}
