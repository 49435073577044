import styled from 'styled-components';

const InfoGaugeOuter = styled.div`
  margin-block-start: 2rem;
`;

const InfoGaugeWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const InfoGaugeContainer = styled.div`
  padding: 1rem;
  background: #f3f9fa;
  flex-basis: 33%;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const InfoGaugeTitle = styled.p`
  margin: 0;
`;

const InfoGaugeNumber = styled.div`
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  margin-block: 1rem;
`;

const InfoGaugePill = styled.span`
  background: ${(props) => {
    switch (props.data) {
      case 'very high':
        return '#CC4839';
      case 'high':
        return '#DA8713';
      case 'low':
        return '#0A819F';
      default:
        return '#000000';
    }
  }};
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-weight: bold;
  margin-block-end: 1rem;
  border-radius: 0.75rem;
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
`;

const InfoGaugeNote = styled.div`
  font-size: 0.75rem;
  line-height: 1.2;
`;

export default function InfoGauge({ storyData }) {
  let infoGaugeData = [];
  const personasLength =
    storyData?.personas &&
    storyData.personas.filter((persona) => {
      return (
        !persona.p_type.includes('Legislator') &&
        persona['first'] !== 'Reading' &&
        persona['first'] !== 'Unidentified'
      );
    }).length;

  const uniqueOids = new Set();
  const contributionValues = storyData?.assets
    ?.find((type) => type.id === 'vote_alignment_org_contribution_success')
    ?.data?.filter((item) => {
      if (!uniqueOids.has(item.oid)) {
        uniqueOids.add(item.oid);
        return true;
      }
      return false;
    });

  const donationTotal = storyData?.assets
    ?.find((type) => type.id === 'vote_alignment_org_contribution_success')
    ?.data?.map((item) =>
      item.donations === 'N/A' ? 0 : Number(item.donations)
    );

  if (personasLength > 0)
    infoGaugeData.push({
      title: 'Testimony',
      value: personasLength,
      valueUnits: 'Non-legislator witnesses spoke',
      floorCheck: storyData.committee?.toLowerCase().includes('floor'),
      floorMessage: 'Floor sessions have no witnesses',
      q1: 2,
      q3: 9,
      maxDisplay: 20,
    });
  if (contributionValues && contributionValues.length > 0) {
    infoGaugeData.push({
      title: 'Org. Interest',
      value: contributionValues.length,
      valueUnits: 'Organizations that took a position',
      floorCheck: false,
      floorMessage: '',
      q1: 0,
      q3: 6,
      maxDisplay: 20,
    });
    if (donationTotal.length > 0) {
      infoGaugeData.push({
        title: 'Donations',
        value: Math.round(
          donationTotal.reduce((a, b) => (a = a + b), 0) / 1000
        ), // nearest thousand
        valueUnits:
          'Donated to committee members by organizations with a position on this discussion',
        floorCheck: false,
        floorMessage: '',
        q1: 0,
        q3: 49,
        maxDisplay: 250,
      });
    }
  }

  const formatNumber = (num, format) => {
    if (format === 'donations') {
      // nearest thousand
      return (num * 1000).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
    } else return num.toLocaleString();
  };

  const infoGaugeDataEvaluation = (value, q1, maxDisplay, q3) => {
    switch (true) {
      case value < q1:
        return 'low';
      case value > maxDisplay:
        return 'very high';
      case value > q3:
        return 'high';
      default:
        return 'typical';
    }
  };

  return (
    <InfoGaugeOuter>
      {infoGaugeData?.length > 0 && (
        <InfoGaugeWrapper>
          {infoGaugeData.map((gaugeData) => (
            <InfoGaugeContainer key={gaugeData.title}>
              <InfoGaugeTitle>{gaugeData.title}</InfoGaugeTitle>
              <div>
                {gaugeData.floorCheck ? (
                  gaugeData.floorMessage
                ) : (
                  <>
                    <InfoGaugeNumber>
                      {formatNumber(
                        gaugeData.value,
                        gaugeData.title?.toLowerCase()
                      )}
                    </InfoGaugeNumber>
                    <InfoGaugePill
                      data={infoGaugeDataEvaluation(
                        gaugeData.value,
                        gaugeData.q1,
                        gaugeData.maxDisplay,
                        gaugeData.q3
                      )}
                    >
                      {infoGaugeDataEvaluation(
                        gaugeData.value,
                        gaugeData.q1,
                        gaugeData.maxDisplay,
                        gaugeData.q3
                      )}
                    </InfoGaugePill>
                    <InfoGaugeNote>
                      {gaugeData.valueUnits}
                      {gaugeData.title === 'Donations' ? (
                        <strong> between 2015 and present</strong>
                      ) : null}
                      . Typical range is{' '}
                      {formatNumber(
                        gaugeData.q1,
                        gaugeData.title?.toLowerCase()
                      )}{' '}
                      to&nbsp;
                      {formatNumber(
                        gaugeData.q3,
                        gaugeData.title?.toLowerCase()
                      )}
                      .
                    </InfoGaugeNote>
                  </>
                )}
              </div>
            </InfoGaugeContainer>
          ))}
        </InfoGaugeWrapper>
      )}
    </InfoGaugeOuter>
  );
}
